import axios from "axios";
import type {StockPrice} from "../components/model/StockPrice";

export default class StockApi {
    kraken;
    api;
    lastMonth = Math.round(Date.now() / 1000) - 24 * 60;

    constructor() {
        this.kraken = axios.create({
            baseURL: "https://api.kraken.com/0/public"
        });
        this.api = axios.create({
            baseURL: "https://parpart.nl/api3",
        });
    }

    getForPair = (pair = "BTCUSD"): Promise<StockPrice> => {
        return this.kraken.get("/Ticker?pair=" + pair)
            .then(response => {
                if (response.data.error != null && response.data.error.length > 0) {
                    throw response.data.error;
                }
                return response.data.result;
            })
            .catch(err => console.log(err));
    };

    // getChart = (pair = "BTCUSD",
    //             interval = 60
    // ): Promise<OHLC> => {
    //     return this.kraken.get(`/OHLC?pair=${pair}&interval=${interval}`)
    //         .then(response => {
    //             if(response.data.error != null && response.data.error.length > 0) {
    //                 throw response.data.error;
    //             }
    //             return  response.data.result;
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         });
    // }

    getCoinData = (coin = "BTC") => {
        return this.api.get(`/chart/getByCoin.php?coin=${coin}`)
            .then(response => {
                return  response.data[0];
            })
            .catch(err => {
                console.log(err);
            });
    }

    getTopCoins = () => {
        return this.api.get(`/chart/getTops.php`)
            .then(response => {
                return  response.data;
            })
            .catch(err => {
                console.log(err);
            });
    }


}