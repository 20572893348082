import React from "react";
import {useEffect, useState} from "react";
import './Header.scss';
import headerSVG from "../assets/images/bitcoin-logo.svg";
import Ticker from "./Ticker";
import {getElementRelativePosition} from "../helper/positionUtils";
import BtcLogo from "../assets/images/coins/bitcoin_logo.svg";
import EthLogo from "../assets/images/coins/ethereum_logo.svg";
import SolLogo from "../assets/images/coins/solana_logo.svg";
import AdaLogo from "../assets/images/coins/cardanao_logo.svg";
import XrpLogo from "../assets/images/coins/ripple_logo.svg";
import DogeLogo from "../assets/images/coins/dogecoin_logo.svg";
import ShibLogo from "../assets/images/coins/shiba-inu_logo.svg";
import UsdtLogo from "../assets/images/coins/tether_logo.svg";
import UsdcLogo from "../assets/images/coins/usdc_logo.svg";
import BusdLogo from "../assets/images/coins/bnb_logo.svg";

function Header() {
    const [openMobile, setOpenMobile] = useState(false);
    const toggleMobile = () => {
        setOpenMobile(!openMobile);
    };

    useEffect(() => {
       window.addEventListener("scroll", isSticky);
       return () => {
           window.removeEventListener("scroll", isSticky)
       }
    });
    const isSticky = (e) => {
        const header = document.getElementById('header');
        const scrollTop = window.scrollY;
        scrollTop >= 100 ? header.classList.add('header-sticky') : header.classList.remove('header-sticky');
    };
    const openDropdownSubnavigation = (button: any, name: string, height: number) => {
        const node = document.querySelector(`.${name}`);
        const mainNode = document.querySelector(".submenu");
        const navNode = document.querySelector(".navi");
        if (node && !node.classList.contains("active")) {
            node.classList.add("active");
            if (mainNode && navNode) {
                mainNode.style.left = getElementRelativePosition(navNode, button).left + "px";
                mainNode.style.height = height + "px";
            }
        }
        if (mainNode && !mainNode.classList.contains("active")) {
            mainNode.classList.add("active");
        }
    };

    const closeDropdownSubnavigation = () => {
        const nodes = document.querySelectorAll(".active");
        nodes.forEach(it => it.classList.remove("active"));
        const mainNode = document.querySelector(".submenu");
        if (mainNode && mainNode.classList.contains("active")) {
            mainNode.classList.remove("active");
        }
    };

    return <header className="header" id="header">
        <div className="header-wrapper">
            <div className="header-logo">
                <a href="https://btcleaks.com"
                   title={"Crypto News on BtcLeaks.com"}
                   aria-label="btcLeaks.com Home">
                    <img src={headerSVG} alt={"btcLeaks.com"} title={"btcLeaks.com"}/>
                </a>
            </div>
            <div className="navi">
                <div className="nav-links-top">
                    <Ticker/>
                </div>
                <button className="mobile-button" onClick={toggleMobile} title={"Mobile Navigation"}>
              <span className="mobile-button-icon">
                <svg width="18" height="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 12" fill="none"
                     focusable="false">
                <g className="burger-icon-group">
                  <path d="M0 0H18V2H0V0Z" fill="#495057"
                        className="burger-icon-group__path burger-icon-group__path--top"></path>
                  <path d="M0 5H18V7H0V5Z" fill="#495057"
                        className="burger-icon-group__path burger-icon-group__path--middle"></path>
                  <path d="M18 10H0V12H18V10Z" fill="#495057"
                        className="burger-icon-group__path burger-icon-group__path--bottom"></path>
                </g>
              </svg>
              </span>
                    <span className="mobile-button-text">Menü</span>
                </button>
                {openMobile &&
                    <div className="mobile-menu-active" onClick={toggleMobile} role={"button"}
                         tabIndex={0}  onKeyDown={toggleMobile}>
                        <div className="mobile-menu-wrapper">
                            <ul className="mobile-menu-links">
                                <li><a href={"/altcoin"} title={"Altcoins | Bitcoin, Ethereum, Solana, Cardano, Polkdadot, ..."}
                                       className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">Altcoins</span>
                                </a></li>
                                <li className="subPoint"><a href={"/bitcoin"} title={"Bitcoin News"}
                                       className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">
                                         <img src={BtcLogo} alt={"Bitcoin"}/> Bitcoin (BTC)</span>
                                    <a className={"chartIcon mobile"} href={"/chart/bitcoin"}
                                       title={"Bitcoin chart and market data"}></a>
                                </a>
                                </li>
                                <li className="subPoint"><a href={"/ethereum"} title={"Ethereum News"}
                                       className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">
                                        <img src={EthLogo} alt={"Ethereum"}/> Ethereum (ETH)</span>
                                    <a className={"chartIcon mobile"} href={"/chart/ethereum"}
                                       title={"Ethereum chart and market data"}></a>
                                </a></li>
                                <li className="subPoint"><a href={"/cardano"} title={"Cardano News"}
                                       className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">
                                        <img src={AdaLogo} alt={"Cardano"}/> Cardano (ADA)</span>
                                    <a className={"chartIcon mobile"} href={"/chart/cardano"}
                                       title={"Cardano chart and market data"}></a>
                                </a></li>
                                <li className="subPoint"><a href={"/solana"} title={"Solana News"}
                                       className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">
                                        <img src={SolLogo} alt={"Solana"}/> Solana (SOL)</span>
                                    <a className={"chartIcon mobile"} href={"/chart/solana"}
                                       title={"Solana chart and market data"}></a>
                                </a></li>
                                <li className="subPoint"><a href={"/ripple"} title={"Ripple XRP News"}
                                       className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">
                                        <img src={XrpLogo} alt={"Ripple"}/> Ripple (XRP)</span>
                                    <a className={"chartIcon mobile"} href={"/chart/ripple"}
                                       title={"Ripple chart and market data"}></a>
                                </a></li>
                                <li><a href={"/memecoin"} title={"Memecoins like Doge and Shiba Ina"}
                                       className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">Memecoins</span>
                                </a></li>
                                <li className="subPoint"><a href={"/doge"} title={"Doge and Doge related coin News"}
                                                            className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">
                                        <img src={DogeLogo} alt={"Dogecoin"}/> Dogecoin</span>
                                    <a className={"chartIcon mobile"} href={"/chart/doge"}
                                       title={"Doge chart and market data"}></a>
                                </a></li>
                                <li className="subPoint"><a href={"/shiba-inu"} title={"Shiba Inu and related coins"}
                                                            className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">
                                        <img src={ShibLogo} alt={"Shiba Inu"}/> Shiba Inu</span>
                                    <a className={"chartIcon mobile"} href={"/chart/shiba-inu"}
                                       title={"Shiba Inu chart and market data"}></a>
                                </a></li>
                                <li><a href={"/stablecoin"} title={"All about Stablecoins: USDT, USDC, ..."}
                                       className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">Stablecoins</span>
                                </a></li>
                                <li className="subPoint"><a href={"/usdt"} title={"Tether and USDT News"}
                                                            className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">
                                        <img src={UsdtLogo} alt={"Tether"}/> Tether (USDT)</span>
                                    <a className={"chartIcon mobile"} href={"/chart/tether"}
                                       title={"USDT chart and market data"}></a>
                                </a></li>
                                <li className="subPoint"><a href={"/usdc"} title={"USDC News"}
                                                            className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">
                                        <img src={UsdcLogo} alt={"USDC"}/> USD Coin (USDC)</span>
                                    <a className={"chartIcon mobile"} href={"/chart/usdc"}
                                       title={"USDC chart and market data"}></a>
                                </a></li>
                                <li className="subPoint"><a href={"/busd"} title={"BUSD News"}
                                                            className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">
                                        <img src={BusdLogo} alt={"BUSD"}/> Binance (BUSD)</span>
                                </a></li>
                                <li><a href={"/markets"} title={"All about binance, coinbase and other exchanges"}
                                       className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">Markets and exchanges</span>
                                </a></li>
                                <li className="subPoint"><a href={"/binance"} title={"Binance"}
                                                            className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">Binance</span>
                                </a></li>
                                <li className="subPoint"><a href={"/coinbase"} title={"Coinbase"}
                                                            className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">Coinbase</span>
                                </a></li>
                                <li className="subPoint"><a href={"/cryptocom"} title={"Crypto.com"}
                                                            className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">Crypto.com</span>
                                </a></li>
                                <li><a href={"/nft"} title={"NFT - Non Fungible Tokens"}
                                       className="mobile-menu-link">
                                    <span className="mobile-menu-link-text">NFT</span>
                                </a></li>
                            </ul>
                        </div>
                    </div>
                }
                <div className="mobile-menu">
                    <div className="mobile-menu-wrapper">
                        <ul className="mobile-menu-links">
                            <li>
                                <a href={"/altcoin"} title={"Altcoins | Bitcoin, Ethereum, Cardano, Solana, Ripple, ..."}
                                   className="mobile-menu-link">
                                    <span className="mobile-menu-link-text" role={"toolbar"}
                                          onMouseOver={(e) => openDropdownSubnavigation(e.target,"altcoins", 240)}
                                          onFocus={(e) => openDropdownSubnavigation(e.target,"altcoins", 240)}
                                          onMouseOut={() => closeDropdownSubnavigation()}
                                          onBlur={() => closeDropdownSubnavigation()}
                                    >
                                        Altcoins
                                    </span>
                                </a>
                            </li>
                            <li><a href={"/memecoin"} title={"Memecoins like Doge and Shiba Ina"}
                                   className="mobile-menu-link">
                                <span className="mobile-menu-link-text" role={"toolbar"}
                                      onMouseOver={(e) => openDropdownSubnavigation(e.target,"memecoins", 96)}
                                      onFocus={(e) => openDropdownSubnavigation(e.target,"memecoins",96)}
                                      onMouseOut={() => closeDropdownSubnavigation()}
                                      onBlur={() => closeDropdownSubnavigation()}
                                >
                                    Memecoins
                                </span>
                            </a></li>
                            <li><a href={"/stablecoin"} title={"All about Stablecoins: USDT, USDC, ..."}
                                   className="mobile-menu-link">
                                <span className="mobile-menu-link-text" role={"toolbar"}
                                      onMouseOver={(e) => openDropdownSubnavigation(e.target,"stablecoins", 144)}
                                      onFocus={(e) => openDropdownSubnavigation(e.target,"stablecoins", 144)}
                                      onMouseOut={() => closeDropdownSubnavigation()}
                                      onBlur={() => closeDropdownSubnavigation()}
                                >
                                    Stablecoins
                                </span>
                            </a></li>
                            <li><a href={"/markets"} title={"All about binance, coinbase and other exchanges"}
                                   className="mobile-menu-link">
                                <span className="mobile-menu-link-text" role={"toolbar"}
                                      onMouseOver={(e) => openDropdownSubnavigation(e.target,"markets", 192)}
                                      onFocus={(e) => openDropdownSubnavigation(e.target,"markets", 192)}
                                      onMouseOut={() => closeDropdownSubnavigation()}
                                      onBlur={() => closeDropdownSubnavigation()}
                                >
                                    Exchanges
                                </span>
                            </a></li>
                            <li><a href={"/nft"} title={"NFT - Non Fungible Tokens"}
                                   className="mobile-menu-link">
                                <span className="mobile-menu-link-text">NFT</span>
                            </a></li>
                        </ul>
                    </div>
                </div>

                <div className="submenu-wrapper">
                    <div className="submenu" style={{width: "170px"}}>
                        <div className="inner-submenu">
                            <div className="submenu-item altcoins">
                                <div className="submenu-item-body">
                                    <ul className="submenu-list-items">
                                        <li className={"submenu-list-item"}>
                                            <a href="/bitcoin" className="submenu-item-link">
                                                <img src={BtcLogo} alt={"Bitcoin"}/>
                                                <span className="submenu-item-text">Bitcoin</span>
                                            </a>
                                            <a href="/chart/bitcoin" className={"chartIcon"}
                                               title={"Bitcoin chart and market data"}>
                                            </a>
                                        </li>
                                        <li className={"submenu-list-item"}>
                                            <a href="/ethereum" className="submenu-item-link">
                                                <img src={EthLogo} alt={"Ethereum"}/>
                                                <span className="submenu-item-text">Ethereum</span>
                                            </a>
                                            <a href="/chart/ethereum" className={"chartIcon"}
                                               title={"Ethereum chart and market data"}>
                                            </a>
                                        </li>
                                        <li className={"submenu-list-item"}>
                                            <a href="/solana" className="submenu-item-link">
                                                <img src={SolLogo} alt={"Solana"}/>
                                                <span className="submenu-item-text">Solana</span>
                                            </a>
                                            <a href="/chart/solana" className={"chartIcon"}
                                               title={"Solana chart and market data"}>
                                            </a>
                                        </li>
                                        <li className={"submenu-list-item"}>
                                            <a href="/cardano" className="submenu-item-link">
                                                <img src={AdaLogo} alt={"Cardano"}/>
                                                <span className="submenu-item-text">Cardano</span>
                                            </a>
                                            <a href="/chart/cardano" className={"chartIcon"}
                                               title={"Cardano chart and market data"}>
                                            </a>
                                        </li>
                                        <li className={"submenu-list-item"}>
                                            <a href="/ripple" className="submenu-item-link">
                                                <img src={XrpLogo} alt={"Ripple"}/>
                                                <span className="submenu-item-text">Ripple</span>
                                            </a>
                                            <a href="/chart/ripple" className={"chartIcon"}
                                               title={"Ripple chart and market data"}>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="submenu-item memecoins">
                                <div className="submenu-item-body">
                                    <ul className="submenu-list-items">
                                        <li className={"submenu-list-item"}>
                                            <a href="/doge" title="Dogecoin and related coins"
                                               className="submenu-item-link">
                                                <img src={DogeLogo} alt={"Doge Coin"}/>
                                                <span className="submenu-item-text">Dogecoin</span>
                                            </a>
                                            <a href="/chart/doge" className={"chartIcon"}
                                               title={"Doge chart and market data"}>
                                            </a>
                                        </li>
                                        <li className={"submenu-list-item"}>
                                            <a href="/shiba-inu" title="Shiba Inu Coin"
                                               className="submenu-item-link">
                                                <img src={ShibLogo} alt={"Shiba Inu"}/>
                                                <span className="submenu-item-text">Shiba inu</span>
                                            </a>
                                            <a href="/chart/shiba-inu" className={"chartIcon"}
                                               title={"Shiba Inu chart and market data"}>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="submenu-item stablecoins">
                                <div className="submenu-item-body">
                                    <ul className="submenu-list-items">
                                        <li className={"submenu-list-item"}>
                                            <a href="/usdt"
                                               title="Tether Stablecoin"
                                               className="submenu-item-link">
                                                <img src={UsdtLogo} alt={"Tether"}/>
                                                <span className="submenu-item-text">USDT</span>
                                            </a>
                                            <a href="/chart/tether" className={"chartIcon"}
                                               title={"USDT chart and market data"}>
                                            </a>
                                        </li>
                                        <li className={"submenu-list-item"}>
                                            <a href="/usdc" title="USDC"
                                               className="submenu-item-link">
                                                <img src={UsdcLogo} alt={"USDC"}/>
                                                <span className="submenu-item-text">USDC</span>
                                            </a>
                                            <a href="/chart/usdc" className={"chartIcon"}
                                               title={"USDC chart and market data"}>
                                            </a>
                                        </li>
                                        <li className={"submenu-list-item"}>
                                            <a href="/busd" title="Binance BUSD"
                                               className="submenu-item-link">
                                                <img src={BusdLogo} alt={"BUSD"}/>
                                                <span className="submenu-item-text">BUSD</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="submenu-item markets">
                                <div className="submenu-item-body">
                                    <ul className="submenu-list-items">
                                        <li className={"submenu-list-item"}>
                                            <a href="/binance"
                                               className="submenu-item-link">
                                                <span className="submenu-item-text">Binance</span>
                                            </a>
                                        </li>
                                        <li className={"submenu-list-item"}>
                                            <a href="/coinbase"
                                               className="submenu-item-link">
                                                <span className="submenu-item-text">Coinbase</span>
                                            </a>
                                        </li>
                                        <li className={"submenu-list-item"}>
                                            <a href="/cryptocom"
                                               className="submenu-item-link">
                                                <span className="submenu-item-text">Crypto.com</span>
                                            </a>
                                        </li>
                                        <li className={"submenu-list-item"}>
                                            <a href="/bitfinex"
                                               className="submenu-item-link">
                                                <span className="submenu-item-text">BitFinex</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </header>;
}

export default Header;
