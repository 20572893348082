exports.components = {
  "component---src-components-charts-chart-page-js": () => import("./../../../src/components/charts/ChartPage.js" /* webpackChunkName: "component---src-components-charts-chart-page-js" */),
  "component---src-components-detailspage-details-page-js": () => import("./../../../src/components/detailspage/DetailsPage.js" /* webpackChunkName: "component---src-components-detailspage-details-page-js" */),
  "component---src-components-mainpage-index-js": () => import("./../../../src/components/mainpage/index.js" /* webpackChunkName: "component---src-components-mainpage-index-js" */),
  "component---src-components-overviewpage-archive-page-js": () => import("./../../../src/components/overviewpage/ArchivePage.js" /* webpackChunkName: "component---src-components-overviewpage-archive-page-js" */),
  "component---src-components-overviewpage-overview-js": () => import("./../../../src/components/overviewpage/Overview.js" /* webpackChunkName: "component---src-components-overviewpage-overview-js" */),
  "component---src-components-overviewpage-qand-a-page-js": () => import("./../../../src/components/overviewpage/QandAPage.js" /* webpackChunkName: "component---src-components-overviewpage-qand-a-page-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

