import './Footer.scss';
import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";

function Footer() {

    return <React.Fragment>
        <footer className={"footer"}>
            <section className={"section1"}>
                <div className={"footer-content"}>
                    <div className="row">
                        <div className="footer-left">
                            <div className="content">
                                <div className="main">
                                    <h3>Your Crypto News Network</h3>
                                </div>
                                <div className="sub">
                                    Together we can make a difference.
                                    Follow and contact us on your social media of choice to stay up to date.
                                </div>
                            </div>
                        </div>
                        <div className="footer-right">
                            <div className="content">
                                <div className="main">
                                    <div className={"icons"}>
                                        <div className="icon">
                                            <div className={"iconBox"}>
                                                <a data-cursor="hover" className={"twitter"}
                                                   title={"Join us on twitter"}
                                                   href="https://twitter.com/btcleaks" rel="noreferrer" target="_blank">
                                                </a>
                                                <a className="facebook" data-cursor="hover"
                                                   title={"Reach out to us Facebook"}
                                                   href="https://facebook.com/btcleaksCom"  rel="noreferrer" target="_blank">
                                                </a>
                                                <a className="linkedin" data-cursor="hover"
                                                   title={"Reach out to us LinkedIn"}
                                                   href="https://www.linkedin.com/company/btcleak"  rel="noreferrer" target="_blank">
                                                </a>
                                            </div>
                                            <div className={"iconBox"}>
                                                <a className="reddit" data-cursor="hover"
                                                   title={"Reach out to us via reddit"}
                                                   href="https://www.reddit.com/user/btcleaksCom"  rel="noreferrer" target="_blank">
                                                </a>
                                                <a className="stocktwits" data-cursor="hover"
                                                   title={"Join us on StockTwits"}
                                                   href="https://stocktwits.com/btcLeaks"  rel="noreferrer" target="_blank">
                                                </a>
                                                <a className="email" data-cursor="hover"
                                                   title={"Reach out to us via Mail"}
                                                   href="mailto:admin@btcleaks.com"  rel="noreferrer" target="_blank">
                                                </a>
                                                <a className="rss" data-cursor="hover"
                                                   title={"Join our RSS Feed"}
                                                   href="https://btcleaks.com/rss.xml"  rel="noreferrer" target="_blank">
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={"section2"}>
                <div className="content">
                    <div className="col">
                        <div className={"h5"}>
                            <h5>
                                Legals
                            </h5>
                        </div>
                        <div className="links">
                            <ul>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Privacy Policy"}
                                       href="/privacy-policy">
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Cookie Consent by Google"}
                                       href="https://policies.google.com/technologies/partner-sites?hl=en-US" target={"_blank"} rel={"noreferrer"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> Consent Policy
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Cookie Settings"}
                                       href={"javascript:window.cc.showSettings()"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> Cookie Settings
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col">
                        <div className={"h5"}>
                            <h5>
                                Topics
                            </h5>
                        </div>
                        <div className="links">
                            <ul>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Recent News"}
                                       href="/">
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> Recent News
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Questions and Answers"}
                                       href="/qanda">
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> Q & A
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Charts"}
                                       href={"/chart/bitcoin"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> Charts
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Archive"}
                                       href={"/archive/july-2022"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> Archive
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col">
                        <div className={"h5"}>
                            <h5>
                                News Pages
                            </h5>
                        </div>
                        <div className="links">
                            <ul>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Crypto Potato"}
                                       href="https://cryptopotato.com" target={"_blank"} rel={"noreferrer"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> cryptopotato.com
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Wallstreet News Blog"}
                                       href={"https://wallstreetnews.nl"} target={"_blank"} rel={"noreferrer"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> wallstreetnews.nl
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"CryptoNews.com"}
                                       href="https://cryptonews.com/" target={"_blank"} rel={"noreferrer"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> cryptonews.com
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"RSS Feed Directory"}
                                       href="http://www.wingee.com/" target={"_blank"} rel={"noreferrer"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> wingee.com
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Read and Write Short Stories, Poem, Book Reviews."}
                                       href={"https://mytales.nl"} target={"_blank"} rel={"noreferrer"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> mytales.nl
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col">
                        <div className={"h5"}>
                            <h5>
                                Trusted Exchanges
                            </h5>
                        </div>
                        <div className="links">
                            <ul>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Binance"}
                                       href="https://www.binance.com" target={"_blank"} rel={"noreferrer"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> Binance
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Coinbase"}
                                       href={"https://www.coinbase.com"} target={"_blank"} rel={"noreferrer"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> Coinbase
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Crypto.com"}
                                       href="https://crypto.com" target={"_blank"} rel={"noreferrer"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> crypto.com
                                    </a>
                                </li>
                                <li>
                                    <a data-cursor="hover"
                                       title={"Kraken"}
                                       href={"https://www.kraken.com"} target={"_blank"} rel={"noreferrer"}>
                                        <FontAwesomeIcon icon={faAngleRight} size={"xs"}/> Kraken
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    </React.Fragment>;

}

export default Footer;
