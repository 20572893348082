import React from "react";
import './Ticker.scss';
import StockPriceDisplay from "./StockPriceDisplay";


function Ticker() {

    return [
        <div className={"ticker"} key={"tick1"}>
            <StockPriceDisplay pair={"BTCUSD"} label={"BTC"} fractions={1}/>
            <StockPriceDisplay pair={"ETHUSD"} label={"ETH"} fractions={2} cssClass={"second"}/>
        </div>,
        <div className={"ticker hideMobile"} key={"tick2"}>
            <StockPriceDisplay pair={"ADAUSD"} label={"ADA"} fractions={3}/>
            <StockPriceDisplay pair={"SOLUSD"} label={"SOL"} fractions={2} cssClass={"second"}/>
        </div>,
        <div className={"ticker hideTablet"} key={"tick3"}>
            <StockPriceDisplay pair={"XRPUSD"} label={"XRP"} fractions={2}/>
            <StockPriceDisplay pair={"DOTUSD"} label={"DOT"} fractions={2} cssClass={"second"}/>
        </div>,
        <div className={"ticker hide3"} key={"tick4"}>
            <StockPriceDisplay pair={"DOGEUSD"} label={"DOGE"} fractions={4}/>
            <StockPriceDisplay pair={"SHIBUSD"} label={"SHIB"} fractions={6} cssClass={"second"}/>
        </div>,
    ];
}

export default Ticker;
