import React from "react";
import "./layout.scss";
import Footer from "./Footer";
import Header from "./Header";
import VanillaCookieConsent from "./VanillaCookieConsent";

export default function Layout({children}) {

    return (
        <div className="App">
            <VanillaCookieConsent/>
            <Header/>
            {children}
            <Footer/>
        </div>
    );
}