import React from "react";
import {useEffect, useState} from "react";
import './StockPriceDisplay.scss';
import StockApi from "../myApis/stockApi";

export type StockPriceDisplayProps = {
    pair: string,
    label: string,
    currency: string,
    fractions: number,
}

function StockPriceDisplay({cssClass, pair, label, currency = "$", fractions = 0}:
                               StockPriceDisplayProps) {
    const [stockPrice, setStockPrice] = useState(null);
    const priceObject = stockPrice ? stockPrice[Object.keys(stockPrice)[0]] : {c: ["1"], o: ["1"]};
    const isPrice = Number.parseFloat(priceObject.c[0]);
    const startPrice = Number.parseFloat(priceObject.o);
    const low24h = Number.parseFloat((priceObject && priceObject.l && priceObject.l.length > 1) ? priceObject.l[1] : null);
    const high24h = Number.parseFloat((priceObject && priceObject.h && priceObject.h.length > 1) ? priceObject.h[1] : null);
    const percent = ((isPrice / startPrice * 100) - 100).toFixed(2);

    useEffect(() => {
        const node = document.getElementById("id-price-" + label);
        if (node) {
            node.classList.remove("update");
            setTimeout(() =>
                node.classList.add("update"), 10);
        }
    }, [isPrice, label]);

    useEffect(() => {
        const api = new StockApi();
        const task = () => {
            api.getForPair(pair)
                .then(price => setStockPrice(price))
            ;
        };
        task();
        setInterval(task, 30000);
        return () => clearInterval(task);
    }, [setStockPrice, pair]);

    return stockPrice
        ? <div className={`priceDisplay ${cssClass}`}>
            <div className={"tooltip"}>
                <span className={"priceLabel"}>
                {`${label} `}
                </span>
                <span className={"price"} id={"id-price-" + label}>
                {`${currency}${isPrice.toFixed(fractions)}`}
                </span>
                <span className={"percent " + (percent < 0 ? "negativ" : "positiv")}>
                {percent} %
                </span>
                <div className={"tooltiptext bottom"}>
                    <div className={"mb-2"}>kraken.com live data</div>
                    <div>Opening price: ${startPrice}</div>
                    {low24h && <div>daily low: ${low24h}</div>}
                    {high24h && <div>daily high: ${high24h}</div>}
                    <small>NYSE opening 9:30 a.m. EST</small>
                </div>
            </div>
        </div>
        : <div/>;
}

export default StockPriceDisplay;
