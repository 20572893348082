export const getElementRelativePosition = (parent, elem) => {
    let parentPos = parent.getBoundingClientRect();
    let childPos = elem.getBoundingClientRect();
    let relativePos = {};

    relativePos.top = childPos.top - parentPos.top;
    relativePos.right = childPos.right - parentPos.right;
    relativePos.bottom = childPos.bottom - parentPos.bottom;
    relativePos.left = childPos.left - parentPos.left;

    return relativePos;
};

