import React, {useEffect} from "react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import "vanilla-cookieconsent/dist/cookieconsent";


export default function VanillaCookieConsent() {
    useEffect(() => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments)}
        gtag('js', new Date());

        const handleDeclineCookie = () => {
            gtag('consent', 'update', {
                // 'ad_storage': 'denied',
                // 'functionality_storage': "denied",
                'analytics_storage': 'denied',
                'personalization_storage': "denied",
            });
        };
        const handleAcceptCookie = () => {
            gtag('consent', 'update', {
                'ad_storage': 'granted',
                'analytics_storage': 'granted',
                'functionality_storage': "granted",
                'personalization_storage': "granted",
            });
            gtag('config', 'G-XZ1SQ2KPXW');
        };

        /* eslint-disable */
        window.cc = initCookieConsent();
        window.cc.run({
            current_lang: 'en',
            autoclear_cookies: true,                   // default: false
            // theme_css: '<path-to-cookieconsent.css>',  // 🚨 replace with a valid path
            page_scripts: false,                        // default: false
            // delay: 0,                               // default: 0
            // auto_language: null                     // default: null; could also be 'browser' or 'document'
            // autorun: true,                          // default: true
            // force_consent: false,                   // default: false
            hide_from_bots: true,                  // default: false
            // remove_cookie_tables: false             // default: false
            // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
            // cookie_expiration: 182,                 // default: 182 (days)
            // cookie_domain: location.hostname,       // default: current domain
            // cookie_path: '/',                       // default: root
            // cookie_same_site: 'Lax',                // default: 'Lax'
            // use_rfc_cookie: false,                  // default: false
            // revision: 0,                            // default: 0
            gui_options: {
                consent_modal: {
                    layout: 'cloud',               // box/cloud/bar
                    position: 'bottom center',     // bottom/middle/top + left/right/center
                    transition: 'slide'            // zoom/slide
                },
                settings_modal: {
                    layout: 'bar',                 // box/bar
                    position: 'right',           // left/right
                    transition: 'slide'            // zoom/slide
                }
            },
            onAccept: (cookie) => {
                if (cookie && cookie.categories && cookie.categories.includes("analytics")) {
                    handleAcceptCookie();
                } else {
                    handleDeclineCookie();
                }
            },
            onChange: function (cookie, changed_preferences) {
                if (cookie && cookie.categories && cookie.categories.includes("analytics")) {
                    handleAcceptCookie();
                } else {
                    handleDeclineCookie();
                }
            },
            languages: {
                'en': {
                    consent_modal: {
                        title: 'We use cookies!',
                        description: 'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                        primary_btn: {
                            text: 'Accept all',
                            role: 'accept_all'              // 'accept_selected' or 'accept_all'
                        },
                        secondary_btn: {
                            text: 'Only necessary',
                            role: 'accept_necessary'        // 'settings' or 'accept_necessary'
                        }
                    },
                    settings_modal: {
                        title: 'Cookie preferences',
                        save_settings_btn: 'Save settings',
                        accept_all_btn: 'Accept all',
                        reject_all_btn: 'Reject all',
                        close_btn_label: 'Close',
                        cookie_table_headers: [
                            {col1: 'Name'},
                            {col2: 'Domain'},
                            {col3: 'Expiration'},
                            {col4: 'Description'}
                        ],
                        blocks: [
                            {
                                title: 'Cookie usage',
                                description: 'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="https://policies.google.com/technologies/partner-sites?hl=en-US" class="cc-link" target="_blank">privacy policy</a>.'
                            }, {
                                title: 'Strictly necessary cookies',
                                description: 'These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly',
                                toggle: {
                                    value: 'necessary',
                                    enabled: true,
                                    readonly: true          // cookie categories with readonly=true are all treated as "necessary cookies"
                                },
                                cookie_table: [             // list of all expected cookies
                                    {
                                        col1: 'cc_consent',       // match all cookies starting with "_ga"
                                        col2: 'btcleaks.com',
                                        col3: '182 days',
                                        col4: 'cookie settings',
                                        is_regex: true
                                    }]
                            }, {
                                title: 'Advertisement and Targeting cookies',
                                description: 'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
                                toggle: {
                                    value: 'targeting',
                                    enabled: true,
                                    readonly: true
                                },
                                cookie_table: [             // list of all expected cookies
                                    {
                                        col1: '^__gads',       // match all cookies starting with "_ga"
                                        col2: 'google.com',
                                        col3: '1 years',
                                        col4: 'Used to show you Google Ads.',
                                        is_regex: true
                                    }]
                            }, {
                                title: 'Performance and Analytics cookies',
                                description: 'These cookies allow the website to remember the choices you have made in the past',
                                toggle: {
                                    value: 'analytics',     // your cookie category
                                    enabled: false,
                                    readonly: false
                                },
                                cookie_table: [             // list of all expected cookies
                                    {
                                        col1: '^_ga',       // match all cookies starting with "_ga"
                                        col2: 'google.com',
                                        col3: '2 years',
                                        col4: 'Google Analytics',
                                        is_regex: true
                                    },
                                    {
                                        col1: '_gid',
                                        col2: 'google.com',
                                        col3: '1 day',
                                        col4: 'Google Analytics',
                                    }
                                ]
                            }, {
                                title: 'More information',
                                description: 'For any queries in relation to our policy on cookies and your choices, please <a class="cc-link" href="/privacy-policy">contact us</a>.',
                            }
                        ]
                    }
                }
            }
        });
    });


    return <div/>;
}